import { cn } from "../../utils/cn";

const cloudinaryBaseUrl = "https://res.cloudinary.com/dsr37ut2z/image/upload/v1722893427/assets/project-icons";

export interface ProjectAvatarProps extends React.HTMLAttributes<HTMLImageElement> {
  project: string;
}

export const ProjectAvatar = ({ project, className, ...props }: ProjectAvatarProps) => {
  return (
    <img
      {...props}
      src={`${cloudinaryBaseUrl}/${project}.png`}
      className={cn("size-5", className)}
      alt={`${project} icon`}
    />
  );
};
