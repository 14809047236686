import { chatMessageSchema } from "./ai-agent";
import { z } from "zod";

export const aiChatLogSchema = z.object({
  id: z.string().uuid(),
  createdAt: z.date(),
  updatedAt: z.date(),
  userId: z.string().uuid(),
  agentId: z.string().uuid(),
  input: z.array(chatMessageSchema),
  output: chatMessageSchema,
});
export type AiChatLog = z.infer<typeof aiChatLogSchema>;
export type CreateAiChatLog = Omit<AiChatLog, "id" | "createdAt" | "updatedAt" | "user" | "agent">;
