import { z } from "zod";

export const RewardsProgramInputSchema = z.object({
  name: z.string(),
  programConfig: z.any(),
  updates: z.string(),
  programRefresh: z.string(),
  programPayoutPeriod: z.string(),
  programType: z.string(),
  programDescription: z.string(),
  status: z.string(),
  startsAt: z.coerce.date(),
  endsAt: z.coerce.date(),
  updatedBy: z.string().optional(),
});

export type RewardsProgramInput = z.infer<typeof RewardsProgramInputSchema>;

export const RewardsProgramUpdateInputSchema = z.object({
  id: z.string(),
  program: RewardsProgramInputSchema,
});

export const RewardsProgramByIdInputSchema = z.object({ id: z.string() });

export const BoostInputSchema = z.object({
  status: z.string(),
  rewardsProgramIds: z.array(z.string()),
  boostType: z.string(),
  boostTarget: z.string(),
  multiplier: z.number(),
  startsAt: z.coerce.date(),
  endsAt: z.coerce.date(),
  updatedBy: z.string().optional(),
});

export type BoostInput = z.infer<typeof BoostInputSchema>;

export const BoostUpdateInputSchema = z.object({
  id: z.string(),
  boost: BoostInputSchema,
});

export const BoostByIdInputSchema = z.object({ id: z.string() });

export const BoostBatchInputSchema = z.object({
  boosts: z.array(BoostInputSchema.omit({ updatedBy: true, rewardsProgramIds: true })),
  updatedBy: z.string().optional(),
  rewardsProgramIds: z.array(z.string()),
});

export type BoostBatchInput = z.infer<typeof BoostBatchInputSchema>;
