import { z } from "zod";

/**
 * Schema for a tag in a fact
 */
export const factTagSchema = z.object({
  category: z.string(),
  value: z.string(),
});

/**
 * Schema for creating a new fact
 */
export const createFactSchema = z.object({
  type: z.string(),
  typeId: z.string().uuid(),
  text: z.string(),
  tags: z.array(factTagSchema),
});

/**
 * Type for creating a new fact
 */
export type CreateFactInput = z.infer<typeof createFactSchema>;
