import { z } from "zod";

// Base schemas
export const resourceTypeSchema = z.string();
export type ResourceType = z.infer<typeof resourceTypeSchema>;

// Create feedback schema
export const createAiAgentFeedbackSchema = z.object({
  resourceId: z.string().uuid(),
  resourceType: resourceTypeSchema,
  agentSlug: z.string(),
  isPositive: z.boolean(),
  reason: z.string().nullish(),
  metadata: z.record(z.unknown()).optional().default({}),
});

export type CreateAiAgentFeedback = z.infer<typeof createAiAgentFeedbackSchema>;

// Update feedback schema
export const updateAiAgentFeedbackSchema = z
  .object({
    isPositive: z.boolean().optional(),
    reason: z.string().optional(),
    metadata: z.record(z.unknown()).optional(),
  })
  .strict();

export type UpdateAiAgentFeedback = z.infer<typeof updateAiAgentFeedbackSchema>;

// Complete feedback schema (includes all fields from the model)
export const aiAgentFeedbackSchema = z.object({
  id: z.string().uuid(),
  resourceId: z.string().uuid(),
  resourceType: resourceTypeSchema,
  isPositive: z.boolean(),
  reason: z.string().nullish(),
  metadata: z.record(z.unknown()).optional().default({}),
  createdAt: z.date(),
  version: z.number(),
  agentId: z.string().uuid(),
  userId: z.string().uuid(),
});

export type AiAgentFeedback = z.infer<typeof aiAgentFeedbackSchema>;

// Resource feedback lookup schema
export const feedbackLookupByUserSchema = z.object({
  resourceId: z.string().uuid(),
  resourceType: resourceTypeSchema,
  userId: z.string().uuid(),
});
export const feedbackLookupSchema = z.object({
  resourceId: z.string().uuid(),
  resourceType: resourceTypeSchema,
});

export type AiAgentFeedbackLookup = z.infer<typeof feedbackLookupSchema>;

export type FeedbackTotals = {
  positive: number;
  negative: number;
  total: number;
};
