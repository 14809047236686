import Decimal from "decimal.js";
import { z } from "zod";
import { TokenWithAssociations } from "../token";
import { Requirement, RequirementSchema } from "./requirement";

const RewardTypeSchema = z.enum(["TOKEN_FIXED", "TOKEN_PERCENT", "FLOW_POINTS", "LOTTERY"]);
export type RewardType = z.infer<typeof RewardTypeSchema>;

export type RewardSpec = {
  id: string;
  type: RewardType;
  questId: string;
  createdAt: Date;
  updatedAt: Date;
  tokenId: string | null;
  tokenBudget: Decimal | null;
  tokenSpent: Decimal | null;
  tokenAllocated: Decimal | null;
  tokenFixedAmount: Decimal | null;
  tokenPercentMultiplier: number | null;
  tokenPercentMaxAmount: Decimal | null;
  tokenPercentRequirementId: string | null;
  flowPointsBudget: number | null;
  flowPointsAllocated: number | null;
  flowPointsBoxesAmount: number | null;
  flowPointsKeysAmount: number | null;
};

// This is a utility type that makes all fields in T required except for K.
type StrictRequired<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};

export type RewardSpecTokenFixed = StrictRequired<
  RewardSpec,
  "tokenId" | "tokenBudget" | "tokenSpent" | "tokenFixedAmount"
> & {
  type: "TOKEN_FIXED";
  token: TokenWithAssociations;
};

export type RewardSpecTokenPercent = StrictRequired<
  RewardSpec,
  | "tokenId"
  | "tokenBudget"
  | "tokenSpent"
  | "tokenPercentMultiplier"
  | "tokenPercentMaxAmount"
  | "tokenPercentRequirementId"
> & {
  type: "TOKEN_PERCENT";
  token: TokenWithAssociations;
};

export type RewardSpecFlowPoints = StrictRequired<
  RewardSpec,
  "flowPointsKeysAmount" | "flowPointsBoxesAmount" | "flowPointsBudget" | "flowPointsAllocated"
> & {
  type: "FLOW_POINTS";
};

export type RewardSpecLottery = RewardSpec & {
  type: "FLOW_POINTS";
};

// This is a discriminated union of all possible reward spec types. Allows us to easily switch on the type field.

export function isTokenFixed(rewardSpec: RewardSpec): rewardSpec is RewardSpecTokenFixed {
  return rewardSpec.type === "TOKEN_FIXED";
}

export function isTokenPercent(rewardSpec: RewardSpec): rewardSpec is RewardSpecTokenPercent {
  return rewardSpec.type === "TOKEN_PERCENT";
}

export function isFlowPoints(rewardSpec: RewardSpec): rewardSpec is RewardSpecFlowPoints {
  return rewardSpec.type === "FLOW_POINTS";
}

export function isLottery(rewardSpec: RewardSpec): rewardSpec is RewardSpecLottery {
  return rewardSpec.type === "LOTTERY";
}

/** Used to display reward specs in the UI. */
export type RewardSpecWithMeta = RewardSpec & {
  requirements: Requirement[];
};

export type RewardSpecWithToken = RewardSpec & {
  token: TokenWithAssociations;
};

export const RewardSpecRemoveInputSchema = z.object({
  rewardSpecId: z.string(),
});

// Input types

export type RewardSpecRemoveInput = z.infer<typeof RewardSpecRemoveInputSchema>;

// This is a discriminated union of all possible reward spec types and their required fields.
export const RewardSpecTypeInputDataSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("TOKEN_FIXED"),
    chainId: z.string(),
    token: z.string(),
    tokenBudget: z.number(),
    tokenFixedAmount: z.number(),
  }),
  z.object({
    type: z.literal("TOKEN_PERCENT"),
    chainId: z.string(),
    token: z.string(),
    tokenBudget: z.number(),
    tokenPercentMultiplier: z.number(),
    tokenPercentMaxAmount: z.number(),
    tokenPercentRequirementId: z.string(),
  }),
  z.object({
    type: z.literal("FLOW_POINTS"),
    flowPointsKeysAmount: z.number(),
    flowPointsBoxesAmount: z.number(),
    flowPointsBudget: z.number(),
  }),
  z.object({
    type: z.literal("LOTTERY"),
  }),
]);

// This is the input schema for creating a reward spec. Includes the quest data
export const RewardSpecCreateInputSchema = z.object({
  questId: z.string(),
  requirements: z.array(RequirementSchema).optional(),
  rewardSpec: RewardSpecTypeInputDataSchema,
});

export type RewardSpecCreateInput = z.infer<typeof RewardSpecCreateInputSchema>;
