import { z } from "zod";

const rewardFlowPointsStatusSchema = z.enum(["PENDING", "CLAIMED", "FAILED"]);

const rewardFlowPointsSchema = z.object({
  id: z.string(),
  rewardSpecId: z.string(),
  questEntryId: z.string(),
  error: z.string().nullable(),
  status: rewardFlowPointsStatusSchema,
});

export type RewardFlowPointsStatus = z.infer<typeof rewardFlowPointsStatusSchema>;
export type RewardFlowPoints = z.infer<typeof rewardFlowPointsSchema>;
