import { z } from "zod";
import { Chain } from "../chain";
import { Partner } from "../partner/partner";
import { Tag } from "../tag";
import { Identity } from "../utils/identity";
import { Requirement } from "./requirement";
import { RewardSpecWithMeta } from "./reward-spec";

export const questChannelSchema = z.enum(["UI", "API", "ALL"]).default("UI");
export type QuestChannel = z.infer<typeof questChannelSchema>;

export type Quest = {
  id: string;
  title: string;
  slug: string;
  description: string;
  startsAt: Date;
  endsAt: Date;
  entryCount: number;
  publishedAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
  archivedAt: Date | null;
  partnerId: string;
  chainId: string;
  channel: QuestChannel;
};

export type QuestWithPartner = Identity<
  Quest & {
    partner: Partner;
  }
>;

/** This type includes everything needed to publicly display a quest card. */
export type QuestWithMeta = Identity<
  Quest & {
    requirements: Requirement[];
    partner: Partner;
    chain?: Chain;
    tags: Tag[];
    rewardSpecs: RewardSpecWithMeta[];
  }
>;

export const QuestListInputSchema = z.object({
  startsAt: z.coerce.date().optional(),
  endsAt: z.coerce.date().optional(),
  channel: questChannelSchema.optional(),
});

export type QuestListInput = z.infer<typeof QuestListInputSchema>;

export const QuestCreateInputSchema = z.object({
  title: z.string(),
  partner: z.string(), // slug
  chain: z.string(), // slug
  slug: z
    .string()
    .min(3)
    .regex(/^[a-z0-9]+(?:-[a-z0-9]+)*$/)
    .refine((val) => !val.includes(" "), {
      message: "Slug must not contain whitespace",
    }),
  description: z.string(),
  startsAt: z.coerce.date(),
  endsAt: z.coerce.date(),
  tagIds: z.array(z.string()),
  channel: questChannelSchema,
});

export type QuestCreateInput = z.infer<typeof QuestCreateInputSchema>;

export const QuestUpdateInputSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  startsAt: z.coerce.date(),
  endsAt: z.coerce.date(),
  chain: z.string(), // slug
  partner: z.string(), // slug
  tagIds: z.array(z.string()),
  channel: questChannelSchema,
});

export type QuestUpdateInput = z.infer<typeof QuestUpdateInputSchema>;

export const QuestAllInputSchema = z.object({
  partner: z.string().optional(),
});
export type QuestAllInput = z.infer<typeof QuestAllInputSchema>;

export const QuestByIdInputSchema = z.object({ id: z.string() });
export type QuestByIdInput = z.infer<typeof QuestByIdInputSchema>;

export const QuestEnterInputSchema = z.object({ questId: z.string(), walletId: z.string() });
export type QuestEnterInput = z.infer<typeof QuestEnterInputSchema>;

export const QuestClaimRewardInputSchema = z.object({ questId: z.string() });
export type QuestClaimRewardInput = z.infer<typeof QuestClaimRewardInputSchema>;

export const QuestEntryByIdInputSchema = z.object({ questEntryId: z.string() });
export type QuestEntryByIdInput = z.infer<typeof QuestEntryByIdInputSchema>;

export const QuestAllPaginatedInputSchema = z.object({
  partner: z.string().optional(),
  cursor: z.string().optional(),
});
export type QuestAllPaginatedInput = z.infer<typeof QuestAllPaginatedInputSchema>;
