import { wallet } from "@fscrypto/domain";
import { UserWalletWithWalletAndChain } from "@fscrypto/domain/earn";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { X, CopyIcon } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../components/button/button";
import { Skeleton } from "../../components/skeleton/skeleton";
import { ConnectWalletApi, ConnectWalletProps, WalletFlowView } from "./connect-wallet";
import { useWalletApi } from "./use-wallet-api";
import Web3Connectors, { SUPPORTED_NETWORKS, SupportedNetwork } from "./web3-connectors";
import { ProjectAvatar } from "./network-avatar";
import { ScrollArea } from "../../components/scroll-area/scroll-area";

// By default, if not injected, use the Gumby endpoints
const defaultApi = {
  onGetWallet: async () => {
    try {
      const response = await fetch(`/api/wallet`);
      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  onAddWallet: async (input: wallet.AddressUpsert) => {
    const response = await fetch(`/api/wallets/add`, { method: "POST", body: JSON.stringify(input) });
    return response.json();
  },
  onDeleteWallet: async (id: string) => {
    const response = await fetch(`/api/wallets/${id}/delete`, { method: "POST" });
    return response.json();
  },
} satisfies ConnectWalletApi;

type ConnectWalletInnerProps = ConnectWalletProps & {
  viewState: WalletFlowView;
  setViewState: (view: WalletFlowView) => void;
};

export const ConnectWalletInner = (props: ConnectWalletInnerProps) => {
  const [queryClient] = React.useState(() => new QueryClient());
  return (
    <QueryClientProvider client={queryClient}>
      <ConnectWalletInnerWrapped {...props} />
    </QueryClientProvider>
  );
};

const ConnectWalletInnerWrapped = (props: ConnectWalletInnerProps) => {
  return (
    <>
      {props.viewState.name === "VIEW_WALLETS" && <ViewWallets {...props} />}
      {props.viewState.name === "CONNECT_WALLET" && <ConnectWallet {...props} />}
      {props.viewState.name === "CHOOSE_CHAIN" && <ChooseChain {...props} />}
    </>
  );
};

const ChooseChain = (props: ConnectWalletInnerProps) => {
  if (props.viewState.name !== "CHOOSE_CHAIN") {
    return null;
  }
  return (
    <div className="grid grid-cols-2 gap-2">
      {SUPPORTED_NETWORKS.map((network) => (
        <button
          className="w-full flex flex-row items-center gap-2 bg-bg-primary rounded-md py-3 px-2 hover:bg-bg-tertiary cursor-pointer"
          key={network}
          onClick={() =>
            props.setViewState({
              name: "CONNECT_WALLET",
              chain: network,
              backTo: { name: "CHOOSE_CHAIN", backTo: props.viewState.backTo },
              viewWalletsTo: { name: "VIEW_WALLETS" },
            })
          }
        >
          <ProjectAvatar project={network} />
          {network.charAt(0).toUpperCase() + network.slice(1)}
        </button>
      ))}
    </div>
  );
};

const ConnectWallet = (props: ConnectWalletInnerProps) => {
  const { addWallet } = useWalletApi(props.api ?? defaultApi);
  if (props.viewState.name !== "CONNECT_WALLET") {
    return null;
  }
  const { chain, viewWalletsTo } = props.viewState;

  return (
    <>
      <div className="bg-bg-secondary rounded-lg px-2 min-h-16">
        <Web3Connectors
          network={chain as SupportedNetwork}
          onSuccess={async (connector: wallet.Web3Connector) => {
            addWallet(connector);
            props.setViewState(viewWalletsTo);
          }}
          config={{
            dynamicEnvironmentId: props.config.dynamicEnvironmentId,
            myNearWalletCallbackUrl: props.config.myNearWalletCallbackUrl,
            theme: props.config.theme ?? "dark",
          }}
        />
      </div>
      <div className="flex justify-center mt-4">
        <Button className="text-brand-2" variant="ghost" onClick={() => props.setViewState(viewWalletsTo)}>
          Already connected wallets
        </Button>
      </div>
    </>
  );
};

const ViewWallets = (props: ConnectWalletInnerProps) => {
  if (props.viewState.name !== "VIEW_WALLETS") {
    return null;
  }

  const contentRef = useRef<HTMLDivElement>(null);
  const [needsScroll, setNeedsScroll] = useState(false);
  const { wallet, isWalletPending, getWalletError, removeWallet } = useWalletApi(props.api ?? defaultApi);
  const chain = props.viewState.chain;
  const filteredWallets = wallet?.filter((w) => w.isVerified && (chain ? w.wallet.chain.slug === chain : true));

  // ScrollArea requires an exact height, cannot use max-h-[450px]. We don't want "dead space" when a user only has 1 wallet.
  useEffect(() => {
    // Check if content height exceeds desired height
    if (contentRef.current) {
      setNeedsScroll(contentRef.current.scrollHeight > 450);
    }
  }, [filteredWallets]);

  return (
    <>
      <div>
        {isWalletPending && <Skeleton className="h-14 w-full" />}
        {getWalletError && <div>Error retrieving wallets</div>}
        {filteredWallets && (
          <ScrollArea className={needsScroll ? "h-[450px]" : "h-auto"}>
            <div ref={contentRef}>
              {filteredWallets.map((wallet) => (
                <WalletRowItem
                  key={wallet.id}
                  userWallet={wallet}
                  removeWallet={removeWallet}
                  onSelectWallet={props.onSelectWallet}
                />
              ))}
            </div>
          </ScrollArea>
        )}
        {filteredWallets?.length === 0 && (
          <p className="text-text-tertiary text-center my-8 opacity-50">You haven't connected any wallets yet.</p>
        )}
      </div>
      <div className="flex justify-center">
        <Button
          className="text-brand-2"
          variant="ghost"
          onClick={() =>
            props.setViewState(
              chain
                ? {
                    name: "CONNECT_WALLET",
                    chain,
                    backTo: { name: "VIEW_WALLETS", chain },
                    viewWalletsTo: { name: "VIEW_WALLETS", chain },
                  }
                : { name: "CHOOSE_CHAIN", backTo: { name: "VIEW_WALLETS", chain } },
            )
          }
        >
          Connect a new wallet
        </Button>
      </div>
    </>
  );
};

const WalletRowItem = (props: {
  userWallet: UserWalletWithWalletAndChain;
  removeWallet: (id: string) => void;
  onSelectWallet?: (wallet: UserWalletWithWalletAndChain) => void;
}) => {
  return (
    <div
      className={`w-full flex justify-between items-center bg-bg-primary rounded-md py-3 px-2 my-2 ${props.onSelectWallet ? "cursor-pointer" : ""}`}
      onClick={() => {
        props.onSelectWallet?.(props.userWallet);
      }}
    >
      <div className="flex flex-row items-center gap-2">
        <ProjectAvatar project={props.userWallet.wallet.chain.slug} />
        <div>{truncateAddress(props.userWallet.wallet.address, { maxLength: 30 })}</div>
        <Button
          variant="ghost"
          className="size-7 p-2"
          onClick={(e) => {
            navigator.clipboard.writeText(props.userWallet.wallet.address);
            e.stopPropagation();
          }}
        >
          <CopyIcon className="size-4 cursor-pointer" />
        </Button>
      </div>
      <Button
        className="px-2"
        variant="ghost"
        onClick={(e) => {
          props.removeWallet(props.userWallet.id);
          e.stopPropagation();
        }}
      >
        <X className="size-4 text-fg-tertiary" strokeWidth={2} />
      </Button>
    </div>
  );
};

/**
 * Truncates a wallet address by showing the first 6 and last 6 characters with ellipsis in between.
 * If maxLength is provided and the address is shorter or equal to maxLength, returns the full address.
 * @param address - The address to truncate
 * @param options - Optional configuration object
 * @param options.maxLength - Optional maximum length before truncation is applied
 * @returns The truncated address string (e.g. "0x1234...5678") or full address if shorter than maxLength
 */
export const truncateAddress = (address: string, options?: { maxLength?: number }) => {
  if (options?.maxLength && address.length <= options.maxLength) {
    return address;
  }

  return `${address.slice(0, 6)}...${address.slice(6 * -1)}`;
};
