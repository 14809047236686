import { z } from "zod";

export const chainIdSchema = z
  .union([
    z.literal("flow"),
    z.literal("sei"),
    z.literal("osmosis"),
    z.literal("arbitrum"),
    z.literal("aptos"),
    z.literal("axelar"),
    z.literal("ethereum"),
    z.literal("solana"),
    z.literal("avalanche"),
    z.literal("gnosis"),
    z.literal("olas"),
    z.literal("polygon"),
    z.literal("near"),
    z.literal("blast"),
    z.literal("kaia"),
    z.literal("ink"),
    z.literal("unknown"),
    z.literal("flow-evm"),
    z.literal("swell"),
  ])
  .catch("unknown");

export const rewardSourceTypeSchema = z.enum(["REWARDS_PROGRAM", "USER_QUEST_STATUS", "MANUAL", "QUEST_V2"]);

export type ChainId = z.infer<typeof chainIdSchema>;
export type RewardSourceType = z.infer<typeof rewardSourceTypeSchema>;
