import { z } from "zod";

const tagSchema = z.object({
  category: z.string(),
  value: z.string(),
  createdAt: z.coerce.date(),
});

const newTagSchema = tagSchema.omit({ createdAt: true });

export type Tag = z.infer<typeof tagSchema>;
export type NewTag = z.infer<typeof newTagSchema>;

const rumorSchema = z.object({
  id: z.string().uuid(),
  type: z.string(),
  typeId: z.string(),
  text: z.string(),
  tags: z.array(tagSchema),
  createdAt: z.coerce.date(),
});

const createRumorSchema = rumorSchema.omit({ id: true, createdAt: true, tags: true }).extend({
  tags: z.array(newTagSchema),
});

export type Rumor = z.infer<typeof rumorSchema>;
export type CreateRumor = z.infer<typeof createRumorSchema>;
