import { z } from "zod";
import { Identity } from "../utils/identity";
import { ZodSchema } from "../utils/zod";
import { Wallet, WalletWithChain } from "./wallet";
import { Address } from "../wallet";

export type UserWallet = {
  id: string;
  walletId: string;
  userId: string;
  isVerified: boolean;
  isDefault: boolean;
  createdAt: Date;
  updatedAt: Date;
  disconnectedAt: Date | null;
};

export type UserWalletWithWallet = Identity<UserWallet & { wallet: Wallet }>;
export type UserWalletWithWalletAndChain = Identity<UserWallet & { wallet: WalletWithChain }>;

export const userWalletByChainAddressInputSchema = z.object({ chain: z.string(), address: z.string() });
export type UserWalletByChainAddressInput = z.infer<typeof userWalletByChainAddressInputSchema>;

export const walletConnectorMetaSchema = z.discriminatedUnion("type", [
  z.object({ type: z.literal("Dynamic"), authToken: z.string() }),
  z.object({
    type: z.literal("Aptos"),
    publicKey: z.string(),
    signature: z.string(),
  }),
  z.object({
    type: z.literal("Near"),
    publicKey: z.string(),
    signature: z.string(),
    callbackUrl: z.string().optional(),
  }),
  z.object({
    type: z.literal("Sei"),
    subType: z.discriminatedUnion("type", [
      z.object({ type: z.literal("evm"), signature: z.string() }),
      z.object({
        type: z.literal("sei"),
        signature: z.string(),
        publicKey: z.object({
          type: z.string(),
          value: z.string(),
        }),
      }),
    ]),
  }),
]);

const walletConnectorSchema = z.object({
  address: z.string(),
  chain: z.string(),
  connectorMeta: walletConnectorMetaSchema,
  walletUsed: z.string().optional(),
});

export type WalletConnectorMeta = z.infer<typeof walletConnectorMetaSchema>;
export type WalletConnector = z.infer<typeof walletConnectorSchema>;
export type WalletVerifyFn = (connector: WalletConnector, config: { dynamicPublicKey: string }) => Promise<boolean>;

export type UserWalletNew = Identity<Pick<UserWallet, "walletId" | "isDefault"> & { verify?: WalletConnectorMeta }>;

export const userWalletNewSchema = z.object({
  walletId: z.string(),
  isDefault: z.boolean(),
  verify: walletConnectorMetaSchema.optional(),
}) satisfies ZodSchema<UserWalletNew>;

export function convertUserWalletToEcoAddress(userWallet: UserWalletWithWalletAndChain): Address {
  const address: Address = {
    id: userWallet.id,
    userId: userWallet.userId,
    address: userWallet.wallet.address,
    chain: userWallet.wallet.chain.slug,
    isDefault: userWallet.isDefault,
    isVerified: userWallet.isVerified,
    isPayable: userWallet.wallet.chain.slug !== "sei-evm", // temp hack
    nickname: null,
    createdAt: userWallet.createdAt,
    updatedAt: userWallet.updatedAt,
    deletedAt: null,
  };
  return address;
}

export const WALLET_SIGNING_CONFIG = {
  APTOS: {
    SIGN_MESSAGE: "Sign this message to verify your address with Flipside",
    NONCE: "flip_nonce",
  },
  NEAR: {
    SIGN_MESSAGE: "Sign this message to verify your address with Flipside",
    MY_NEAR_WALLET_SESSION_TOKEN_KEY: "myNearWalletSessionToken",
    nonceAsBase64: "dgMGbH0LHIEy7kSSwhVQdkV4t28Ru36IDy5NmvFpA2M=",
    RECIPIENT: "flipside",
  },
  SEI: {
    SIGN_MESSAGE: "Sign this message to verify your address with Flipside",
  },
};
