import { z } from "zod";
import type { QuestEntry } from "./quest-entry";
import type { Quest } from "./quest";
import Decimal from "decimal.js";

export interface IRequirementDriver {
  verify(requirement: Requirement, entry: QuestEntry): Promise<RequirementResultInput>;
}

export const requirementTypeSchema = z.enum([
  "SCORE",
  "QUEST_COMPLETION",
  "QUEST_NON_COMPLETION",
  "SNOWFLAKE",
  "SNOWFLAKE_TX",
  "SNOWFLAKE_TX_TIMED",
  "TWITTER_FOLLOW",
  "TWITTER_RETWEET",
]);
export const requirementStageSchema = z.enum(["PREREQ", "OBJECTIVE", "REWARD"]);
export const scoreSubscoreSchema = z.enum(["TOTAL", "NFTS", "DEFI", "TOKENS", "GOV", "ACTIVITY"]);
export const mediaTypeSchema = z.enum(["IMAGE", "VIDEO"]);

export type RequirementType = z.infer<typeof requirementTypeSchema>;
export type RequirementStage = z.infer<typeof requirementStageSchema>;
export type ScoreSubscore = z.infer<typeof scoreSubscoreSchema>;
export type MediaType = z.infer<typeof mediaTypeSchema>;

export type Requirement = {
  id: string;
  title: string;
  type: RequirementType;
  stage: RequirementStage;
  description: string | null;
  callToActionUrl: string | null;
  callToActionText: string | null;
  mediaUrl: string | null;
  mediaType: MediaType | null;
  position: number;
  questId: string;
  scoreSubscore: ScoreSubscore | null;
  scoreMin: number | null;
  scoreMax: number | null;
  twitterFollowUsername: string | null;
  twitterRetweetPostId: string | null;
  questCompletionId: string | null;
  snowflakeSql: string | null;
  resultMin: Decimal | null;
  resultMax: Decimal | null;
  rewardSpecId: string | null;
  createdAt: Date;
  updatedAt: Date;
};

export type RequirementWithQuest = Requirement & { quest: Quest };

export const requirementResultStatusSchema = z.enum(["PENDING", "COMPLETE", "FAILED"]);
export type RequirementResultStatus = z.infer<typeof requirementResultStatusSchema>;

export type RequirementResult = {
  id: string;
  status: RequirementResultStatus;
  questEntryId: string;
  requirementId: string;
  result: Decimal;
  error: string | null;
  isVerifying: boolean;
  callbackUrl: string | null;
  createdAt: Date;
  updatedAt: Date;
};

export type RequirementResultWithRequirement = RequirementResult & { requirement: Requirement };

export type SnowflakeTxResult = {
  BLOCK_TIMESTAMP: string;
  TX_ID: string;
  ADDRESS: string;
  VALID: boolean;
  TOKEN_AMOUNT: number;
  FEE_AMOUNT: number;
  CURRENCY: string;
};

export const RequirementSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("SCORE"),
    scoreSubscore: scoreSubscoreSchema,
    scoreMin: z.number(),
    scoreMax: z.number(),
  }),
  z.object({ type: z.literal("QUEST_COMPLETION"), questCompletionId: z.string() }),
  z.object({ type: z.literal("QUEST_NON_COMPLETION"), questCompletionId: z.string() }),
  z.object({
    type: z.literal("SNOWFLAKE"),
    snowflakeSql: z.string(),
    resultMin: z.number(),
    resultMax: z.number().optional(),
    exampleTransactions: z.string().optional(),
  }),
  z.object({
    type: z.literal("SNOWFLAKE_TX"),
    resultMin: z.number(),
    resultMax: z.number().optional(),
    snowflakeSql: z.string(),
    exampleTransactions: z.string().optional(),
  }),
  z.object({
    type: z.literal("SNOWFLAKE_TX_TIMED"),
    resultMin: z.number(),
    resultMax: z.number().optional(),
    snowflakeSql: z.string(),
    exampleTransactions: z.string().optional(),
  }),
  z.object({ type: z.literal("TWITTER_FOLLOW"), twitterFollowUsername: z.string() }),
  z.object({ type: z.literal("TWITTER_RETWEET"), twitterRetweetPostId: z.string() }),
]);

/**
 * Input schema for adding a requirement to a quest.
 */
export const RequirementCreateInputSchema = z.object({
  questId: z.string(),
  stage: requirementStageSchema,
  position: z.number(),
  title: z.string(),
  mediaUrl: z.string().url().optional().nullable(),
  mediaType: mediaTypeSchema.optional().nullable(),
  callToActionUrl: z.string().optional().nullable(),
  callToActionText: z.string().optional().nullable(),
  description: z.string().optional(),
  rewardSpecId: z.string().optional(),
  requirement: RequirementSchema,
});
export type RequirementCreateInput = z.infer<typeof RequirementCreateInputSchema>;

export const RequirementUpdateInputSchema = RequirementCreateInputSchema.omit({ questId: true }).partial().extend({
  requirementId: z.string(),
});
export type RequirementUpdateInput = z.infer<typeof RequirementUpdateInputSchema>;

/** For procedures that operate on a single requirement. */
export const RequirementByIdInputSchema = z.object({
  requirementId: z.string(),
});
export type RequirementByIdInput = z.infer<typeof RequirementByIdInputSchema>;

/** For repositioning the list of requirements in a quest */
export const RequirementRepositionInputSchema = z.object({
  requirementId: z.string(),
  newPosition: z.number(),
});
export type RequirementRepositionInput = z.infer<typeof RequirementRepositionInputSchema>;

/** Requirement results are on a per-entry bases so we in order to check a requirement on an entry we need both the questEntryId and requirementId */
export const CheckRequirementInputSchema = z.object({
  requirementId: z.string(),
  questEntryId: z.string(),
  callbackUrl: z.string().url().optional(),
});
export type CheckRequirementInput = z.infer<typeof CheckRequirementInputSchema>;

export const TransactionResultSchema = z.object({
  blockTimestamp: z.coerce.date(),
  txId: z.string(),
  address: z.string(),
  valid: z.boolean(),
  tokenAmount: z.coerce.number(),
  feeAmount: z.coerce.number(),
  currency: z.string(),
});

export const TransactionResultRawSchema = z.object({
  BLOCK_TIMESTAMP: z.coerce.date(),
  TX_ID: z.string(),
  ADDRESS: z.string(),
  VALID: z.boolean(),
  TOKEN_AMOUNT: z.coerce.number(),
  FEE_AMOUNT: z.coerce.number(),
  CURRENCY: z.string(),
  ERROR: z.string().nullish(),
});
export type TransactionResultRaw = z.infer<typeof TransactionResultRawSchema>;

export const TransactionResultsSchemaRaw = z.array(TransactionResultRawSchema);

/** Schema for Snowflake transaction requirements with explicit uppercase mapping */
export const TransactionResultProcessSchema = z.preprocess((obj) => {
  const data = obj as SnowflakeTxResult;
  return {
    blockTimestamp: data.BLOCK_TIMESTAMP,
    txId: data.TX_ID,
    address: data.ADDRESS,
    valid: data.VALID,
    tokenAmount: data.TOKEN_AMOUNT,
    feeAmount: data.FEE_AMOUNT,
    currency: data.CURRENCY,
  };
}, TransactionResultSchema);

/** Verification result schema for Snowflake transaction requirements. */
export const RequirementTransactionResultSchema = z.object({
  result: TransactionResultProcessSchema,
  error: z.string().optional(),
});
export type RequirementTransactionResult = z.infer<typeof RequirementTransactionResultSchema>;

/** Output schema for for checking a requirement. */
export const requirementDriverOutputSchema = z.object({
  result: z.number(),
  status: z.enum(["PENDING", "COMPLETE", "FAILED"]),
  transaction: TransactionResultSchema.extend({ walletId: z.string(), tokenId: z.string() })
    .omit({ address: true, valid: true, currency: true })
    .optional(),
  error: z.string().optional(),
  callbackUrl: z.string().url().optional(),
});
export type RequirementDriverOutput = z.infer<typeof requirementDriverOutputSchema>;

/** Input schema for adding a requirement result. */
export const RequirementResultInputSchema = z.object({
  result: z.number().optional(),
  status: z.enum(["PENDING", "COMPLETE", "FAILED"]).optional(),
  transaction: TransactionResultSchema.extend({ walletId: z.string(), tokenId: z.string() })
    .omit({ address: true, valid: true, currency: true })
    .optional(),
  error: z.string().optional(),
  callbackUrl: z.string().url().optional(),
  questEntryId: z.string(),
  requirementId: z.string(),
});
export type RequirementResultInput = z.infer<typeof RequirementResultInputSchema>;
