import { z } from "zod";

const profileNameRegex = /^[a-zA-Z0-9-_]+$/;

export const profileSlugSchema = z
  .string()
  .min(3, "Must be a minimum of 3 characters")
  .max(42, "Must be a maximum of 42 characters")
  .regex(profileNameRegex, "Must be alphanumeric, hyphen, or underscore");

const defaultWarehouseTypeSchema = z.enum(["free", "paid"]);

export const schema = z.object({
  id: z.string().uuid(),
  slug: profileSlugSchema,
  type: z.enum(["user", "team"]),
  stripeId: z.string().optional().nullable(),
  lagoSubscriptionId: z.string().nullable().optional(),
  isSnowflakeAccessEnabled: z.boolean().optional(),
  fixedSlug: z.string().nullable().optional(),
  createdAt: z.date().default(new Date()),
  updatedAt: z.date().default(new Date()),
  domainType: z.literal("Profile").default("Profile"),
  avatarUrl: z.string().optional().nullable(),
  profileUrl: z.string().optional(),
  name: z.string().optional(),
  defaultWarehouseType: defaultWarehouseTypeSchema,
  needsBilling: z.boolean().default(false),
  isQueryDefaultPrivate: z.boolean().default(false),
  isDashboardDefaultPrivate: z.boolean().default(false),
  twitterHandle: z.string().optional().nullable(),
  discordHandle: z.string().optional().nullable(),
});

export const slugSchema = schema.pick({
  slug: true,
});

export const publicSchema = schema.pick({
  id: true,
  slug: true,
  type: true,
  avatarUrl: true,
  profileUrl: true,
  name: true,
  fixedSlug: true,
  twitterHandle: true,
  discordHandle: true,
});

export const upsertSchema = schema
  .pick({
    slug: true,
    type: true,
    stripeId: true,
    lagoSubscriptionId: true,
    isSnowflakeAccessEnabled: true,
    fixedSlug: true,
    defaultWarehouseType: true,
    needsBilling: true,
    isQueryDefaultPrivate: true,
    isDashboardDefaultPrivate: true,
  })
  .partial();

export const getProfileUrl = (obj: Profile): string => {
  return obj.type === "user" ? `/${obj.slug}/dashboards` : `/teams/${obj.slug}/dashboards`;
};

export const isInternal = (slug: string): boolean => {
  return slug === "flipsideBI";
};

export type Profile = z.infer<typeof schema>;
export type ProfilePublic = z.infer<typeof publicSchema>;
export type ProfileUpsert = z.infer<typeof upsertSchema>;
export type ProfileDefaultWarehouseType = z.infer<typeof defaultWarehouseTypeSchema>;
