import { isFlowPoints, isTokenFixed, isTokenPercent, type QuestWithMeta } from "@fscrypto/domain/earn";
import { Text } from "@fscrypto/flip-kit/text";
import { Progress } from "~/components/ui/progress";

// Formats the remaining number based on the total number of rewards.
function formatRemainingNumber(remaining: number, total: number): string {
  if (remaining <= 0) return "0";
  if (total <= 1) return remaining.toFixed(2);
  if (total <= 10) return remaining.toFixed(1);
  return Math.floor(remaining).toString();
}

export const getRewardsRemaining = (quest: QuestWithMeta) => {
  let spent = 0;
  let total = 0;

  for (const rewardSpec of quest.rewardSpecs) {
    if (isTokenFixed(rewardSpec) || isTokenPercent(rewardSpec)) {
      spent += rewardSpec.tokenAllocated?.toNumber() || 0;
      total += rewardSpec.tokenBudget.toNumber();
    }
    if (isFlowPoints(rewardSpec)) {
      spent += rewardSpec.flowPointsAllocated;
      total += rewardSpec.flowPointsBudget;
    }
  }

  let remaining = total - spent;
  if (remaining < 0) remaining = 0;

  return { remaining, total };
};

export const ProgressRewardsRemaining = ({
  remaining,
  total,
  theme,
}: {
  remaining: number;
  total: number;
  theme?: "default" | "dark";
}) => {
  const remainingFormatted = formatRemainingNumber(remaining, total);

  return (
    <>
      <div className="mb-2">
        <Text variant="body" className="mr-1">
          {remainingFormatted}
        </Text>
        <Text variant="caption" className="text-text-tertiary">
          / {total}
        </Text>
      </div>
      <Progress variant={theme} value={(remaining / total) * 100} />
    </>
  );
};
