import { z } from "zod";
import { QuestWithPartner } from "./quest";
import { RequirementResult } from "./requirement";
import { RewardEarning } from "./reward-earning";
import { Wallet, WalletWithChain } from "./wallet";

export const questEntryStatusSchema = z.enum(["STARTED", "COMPLETED", "CLAIMED"]);
export type QuestEntryStatus = z.infer<typeof questEntryStatusSchema>;

export type QuestEntry = {
  id: string;
  questId: string;
  userId: string | null;
  status: QuestEntryStatus;
  score: number | null;
};

// This type is used by the client to display the quest in the history list.
export type QuestEntryWithMeta = QuestEntry & {
  quest: QuestWithPartner;
  results: RequirementResult[];
};

export type QuestEntryWithWallet = QuestEntry & {
  walletId: string | null;
  wallet: Wallet | null;
};

export type QuestEntryWithWalletAndChain = QuestEntry & {
  walletId: string | null;
  wallet: WalletWithChain | null;
};

// This type is used by the client to display the status of the entry including the results of the requirement.
// Think of this like a "report card" for the user's progress in the quest.
export type QuestEntryWithResults = QuestEntry & {
  walletId: string | null;
  wallet: Wallet | null;
  results: RequirementResult[];
  rewardEarnings: RewardEarning[];
};
