import { z } from "zod";
import { decimalSchema } from "../utils/zod";

export enum RewardSourceTypeEnum {
  QUEST_V2 = "QUEST_V2",
  QUEST = "QUEST",
  USER_QUEST_STATUS = "USER_QUEST_STATUS",
  REWARDS_PROGRAM = "REWARDS_PROGRAM",
  MANUAL = "MANUAL",
}

export enum SpendSourceTypeEnum {
  QUEST = "QUEST",
  REWARD_SPEC = "REWARD_SPEC",
  USER_QUEST_STATUS = "USER_QUEST_STATUS",
  REWARDS_PROGRAM = "REWARDS_PROGRAM",
  MANUAL = "MANUAL",
}

export enum PaymentStatus {
  CREATED = "CREATED",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  QUEUED = "QUEUED",
  UNRESOLVED = "UNRESOLVED",
}

export interface RpcResponse<T> {
  result?: { data: T };
  error?: { message: string; httpStatus: number; code: string };
}

export const CreateRewardInputSchema = z.object({
  userId: z.string(),
  profileId: z.string(),
  chain: z.string(),
  tokenName: z.string(),
  rewardSourceType: z.nativeEnum(RewardSourceTypeEnum),
  rewardSourceId: z.string(),
  spendSourceType: z.nativeEnum(SpendSourceTypeEnum),
  spendSourceId: z.string(),
  partnerId: z.string(),
  tokenValue: z.string().optional(),
  usdValue: z.string().optional(),
  priceAt: z.date().optional(),
});

export type CreateRewardInput = z.infer<typeof CreateRewardInputSchema>;

export const GetBalanceInputSchema = z.object({
  userId: z.string(),
  chain: z.string().optional(),
  tokenName: z.string().optional(),
});

export type GetBalanceInput = z.infer<typeof GetBalanceInputSchema>;

export const UserRewardSchema = z.object({
  id: z.string(),
  userId: z.string(),
  profileId: z.string(),
  chain: z.string(),
  currency: z.string(),
  tokenEarned: decimalSchema,
  tokenClaimed: decimalSchema,
  usdEarned: decimalSchema,
  usdClaimed: decimalSchema,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  RewardSourceType: z.nativeEnum(RewardSourceTypeEnum),
  rewardSourceId: z.string(),
  status: z.enum(["CLAIMED", "CLAIMABLE"]).optional(),
});

export type UserReward = z.infer<typeof UserRewardSchema>;

export const UserBalanceSchema = z.object({
  chain: z.string(),
  tokenName: z.string(),
  tokenBalance: decimalSchema,
  usdBalance: decimalSchema,
  userRewardsProgramRollups: z.array(UserRewardSchema),
});

export type UserBalance = z.infer<typeof UserBalanceSchema>;

export type GetPaymentHistoryInput = {
  userId: string;
  profileId?: string;
  chain?: string;
  tokenName?: string;
};

export const PaymentSchema = z.object({
  paymentId: z.string(),
  ecoUserId: z.string(),
  profileId: z.string(),
  txHash: z.string(),
  paymentChain: z.string(),
  tokenName: z.string(),
  status: z.nativeEnum(PaymentStatus),
  recipientAddress: z.string(),
  tokenAmount: decimalSchema,
  usdAmount: decimalSchema,
  urprId: z.string(),
  paymentName: z.string().nullable(),
  sourceType: z.nativeEnum(RewardSourceTypeEnum),
  sourceId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type Payment = z.infer<typeof PaymentSchema>;
