import { type Team } from "@fscrypto/domain/team";
import type { User } from "@fscrypto/domain/user";
import { ChevronDown, MenuIcon } from "lucide-react";
import React from "react";
import { Avatar, AvatarFallback, AvatarImage } from "../../components/avatar/avatar";
import { Button } from "../../components/button/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/dropdown-menu/dropdown-menu";
import { TrackedLink } from "../../components/tracked-click/TrackedLink";
import { LogoIcon } from "../../primitives/logo";
import { Text } from "../../primitives/text/text";
import { cn } from "../../utils/cn";
import ConnectWallet from "../connect-wallet/connect-wallet";

interface HeaderProps {
  rewardCount?: number;
  renderMenuContent?: React.ReactNode;
  authMenuContent?: React.ReactNode;
  user?: User | null;
  team?: Team | null;
  dark?: boolean;
  transparent?: boolean;
  tracking?: (event: string) => void;
  onLogout?: () => void;
  connectWalletConfig?: {
    dynamicEnvironmentId: string;
    myNearWalletCallbackUrl: string;
  };
}

export const Header: React.FC<HeaderProps> = ({
  rewardCount = 0,
  renderMenuContent,
  authMenuContent,
  user,
  team,
  dark,
  transparent,
  tracking,
  onLogout,
  connectWalletConfig,
}) => {
  return (
    <header
      className={cn("bg-bg-primary w-full relative z-30 bg-bg-black py-6 md:py-4", {
        dark: dark,
        "bg-transparent": !!transparent,
        "bg-bg-primary": !transparent,
      })}
    >
      <div className="w-full items-center justify-between px-10 hidden min-[1090px]:flex">
        <HeaderLogo />
        <HeaderLeftNav tracking={tracking} />
        <NavDivider />
        <HeaderRightNav tracking={tracking} />
        <HeaderAuth
          rewardCount={rewardCount}
          renderMenuContent={renderMenuContent}
          authMenuContent={authMenuContent}
          user={user}
          team={team}
          tracking={tracking}
          onLogout={onLogout}
          connectWalletConfig={connectWalletConfig}
          dark={dark}
        />
      </div>
      <MobileNav user={user} connectWalletConfig={connectWalletConfig} />
    </header>
  );
};

const HeaderLogo: React.FC = () => (
  <a href="/" aria-label="Flipside" className="flex items-center space-x-2">
    <LogoIcon className="h-8 w-auto" />
    <h1 className="text-xl">flipside</h1>
  </a>
);

const NavDivider: React.FC = () => <div className="h-8 w-px bg-border-secondary" />;

const HeaderLeftNav: React.FC<{ tracking?: (event: string) => void }> = ({ tracking }) => (
  <nav className="hidden space-x-4 md:flex">
    <HeaderNavItem href="/insights/dashboards" onClick={tracking && (() => tracking("nav_click_insights"))}>
      Insights
    </HeaderNavItem>
    <HeaderNavItem href="/edit" onClick={tracking && (() => tracking("nav_click_studio"))}>
      Studio
    </HeaderNavItem>
    <HeaderNavItem href="/earn/" onClick={tracking && (() => tracking("nav_click_quests"))}>
      Quests
    </HeaderNavItem>
  </nav>
);

const HeaderRightNav: React.FC<{ tracking?: (event: string) => void }> = ({ tracking }) => (
  <nav className="hidden space-x-4 md:flex justify-between flex-1 max-w-[600px]">
    <HeaderNavItem href="/home/chains" onClick={tracking && (() => tracking("nav_click_chains"))}>
      For Chains
    </HeaderNavItem>
    <HeaderNavItem href="/home/analysts" onClick={tracking && (() => tracking("nav_click_analysts"))}>
      For Analysts
    </HeaderNavItem>
    <HeaderNavItem href="/home/explorers" onClick={tracking && (() => tracking("nav_click_explorers"))}>
      For Explorers
    </HeaderNavItem>
    <AboutMenu tracking={tracking} />
  </nav>
);

interface HeaderNavItemProps {
  href: string;
  children: React.ReactNode;
  onClick?: (() => void) | undefined;
}

const HeaderNavItem: React.FC<HeaderNavItemProps> = ({ href, children, onClick }) => (
  <TrackedLink href={href} className="text-sm block px-2 relative font-medium" onClick={onClick}>
    {children}
  </TrackedLink>
);

const HeaderAuth: React.FC<HeaderProps> = ({
  rewardCount = 0,
  team,
  renderMenuContent,
  authMenuContent,
  user,
  onLogout,
  dark,
  connectWalletConfig,
  tracking,
}) => (
  <div className="flex items-center space-x-4">
    {authMenuContent && <div className="ml-2">{authMenuContent}</div>}
    {user ? (
      <>
        <HeaderNavItem href="/rewards">
          Rewards
          {rewardCount > 0 && (
            <div className="absolute right-0 top-0 -translate-y-1/2 translate-x-1/2 transform rounded-full bg-red-500 px-1 text-xs text-white">
              {rewardCount}
            </div>
          )}
        </HeaderNavItem>
        <UserMenu
          user={user}
          team={team}
          renderMenuContent={renderMenuContent}
          onLogout={onLogout}
          dark={dark}
          connectWalletConfig={connectWalletConfig}
        />
      </>
    ) : (
      <>
        <HeaderAuthButton
          variant="ghost"
          href="/home/login"
          onClick={tracking ? () => tracking("nav_click_login") : undefined}
        >
          Log in
        </HeaderAuthButton>
        <HeaderAuthButton
          variant="default"
          href="/home/login?screen_hint=signup"
          onClick={tracking ? () => tracking("nav_click_signup") : undefined}
        >
          Sign up
        </HeaderAuthButton>
      </>
    )}
  </div>
);

interface HeaderAuthButtonProps {
  variant: "default" | "ghost" | "outline";
  href: string;
  children: React.ReactNode;
  className?: string;
  onClick?: (() => void) | undefined;
}

const HeaderAuthButton: React.FC<HeaderAuthButtonProps> = ({ variant, href, children, className, onClick }) => (
  <TrackedLink href={href} onClick={onClick}>
    <Button variant={variant} className={className}>
      {children}
    </Button>
  </TrackedLink>
);

interface UserMenuProps {
  renderMenuContent?: React.ReactNode;
  user: User;
  team?: Team | null;
  onLogout?: () => void;
  dark?: boolean;
  connectWalletConfig?: HeaderProps["connectWalletConfig"];
}

const UserMenu: React.FC<UserMenuProps> = ({ user, team, renderMenuContent, onLogout, dark, connectWalletConfig }) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <div className="items-center cursor-pointer flex space-x-1">
        <Avatar className="size-6">
          <AvatarImage src={user.avatarUrl} alt={user.username} />
          <AvatarFallback>{user.username.charAt(0).toUpperCase()}</AvatarFallback>
        </Avatar>
        {team && (
          <div className="bg-bg-tertiary/50 p-1 pr-3 rounded-full flex items-center space-x-2 text-sm">
            <Avatar className="size-6">
              <AvatarImage src={team.avatarUrl} />
              <AvatarFallback>{team.name.charAt(0).toUpperCase()}</AvatarFallback>
            </Avatar>
            <span>{team.name}</span>
          </div>
        )}
        <ChevronDown className="size-4 stroke-1" />
      </div>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end" className="w-60">
      <div className="px-3 py-2 text-sm font-normal">
        <div className="flex flex-col space-y-1">
          <p>{user.username}</p>
          <p className="text-text-tertiary">{user.email}</p>
        </div>
      </div>
      {renderMenuContent && (
        <>
          <DropdownMenuSeparator />
          {renderMenuContent}
        </>
      )}
      <DropdownMenuSeparator />
      {team && (
        <DropdownMenuItem asChild>
          <a className="h-full w-full cursor-pointer" href={`/${team.slug}`}>
            Team Profile
          </a>
        </DropdownMenuItem>
      )}
      <DropdownMenuItem asChild>
        <a className="h-full w-full cursor-pointer" href={`/${user.username}`}>
          Profile
        </a>
      </DropdownMenuItem>
      {connectWalletConfig && (
        <ConnectWallet
          config={{
            dynamicEnvironmentId: connectWalletConfig.dynamicEnvironmentId,
            myNearWalletCallbackUrl: connectWalletConfig.myNearWalletCallbackUrl,
            theme: dark ? "dark" : "light",
          }}
        >
          <DropdownMenuItem asChild onSelect={(event) => event.preventDefault()}>
            <span className="h-full w-full cursor-pointer">Wallets</span>
          </DropdownMenuItem>
        </ConnectWallet>
      )}
      <DropdownMenuItem asChild>
        <a className="h-full w-full cursor-pointer" href="/settings/plan">
          Settings
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <a className="h-full w-full cursor-pointer" href="/rewards">
          Rewards
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <a className="h-full w-full cursor-pointer" href="/earn/history">
          Quest History
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <form action="/home/logout" method="post" onSubmit={onLogout}>
          <button type="submit" className="h-full w-full cursor-pointer text-left">
            Log out
          </button>
        </form>
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
);

const AboutMenuContent = ({ tracking }: { tracking?: (event: string) => void }) => (
  <>
    <DropdownMenuItem>
      <a href="/home/about" onClick={tracking && (() => tracking("nav_click_company"))}>
        <Text variant="button">Company</Text>
      </a>
    </DropdownMenuItem>
    <DropdownMenuItem>
      <a
        href="https://flipsidecrypto.xyz/fc/resources"
        target="_blank"
        onClick={tracking && (() => tracking("nav_click_resources"))}
      >
        <Text variant="button">Resources</Text>
      </a>
    </DropdownMenuItem>
    <DropdownMenuItem>
      <a
        href="https://docs.flipsidecrypto.xyz/"
        target="_blank"
        onClick={tracking && (() => tracking("nav_click_documentation"))}
      >
        <Text variant="button">Documentation</Text>
      </a>
    </DropdownMenuItem>
    <DropdownMenuItem>
      <a
        href="https://flipsidecrypto.breezy.hr/"
        target="_blank"
        onClick={tracking && (() => tracking("nav_click_careers"))}
      >
        <Text variant="button">Careers</Text>
      </a>
    </DropdownMenuItem>
  </>
);

export const AboutMenu = ({ tracking }: { tracking?: (event: string) => void }) => (
  <DropdownMenu>
    <DropdownMenuTrigger>
      <div
        className="flex items-center space-x-1 px-7 cursor-pointer"
        onClick={tracking && (() => tracking("nav_click_about"))}
      >
        <span className="text-sm font-medium">About</span>
        <ChevronDown className="size-4 stroke-1" />
      </div>
    </DropdownMenuTrigger>
    <DropdownMenuContent>
      <AboutMenuContent tracking={tracking} />
    </DropdownMenuContent>
  </DropdownMenu>
);

interface MobileMenuProps {
  user?: User | null;
  connectWalletConfig?: HeaderProps["connectWalletConfig"];
}

const MobileNav: React.FC<MobileMenuProps> = ({ user, connectWalletConfig }) => (
  <div className="min-[1090px]:hidden w-full flex items-center justify-between px-4">
    <HeaderLogo />
    <div className="flex items-center space-x-1">
      <MobileMenu user={user} />
      {user && <UserMenu user={user} connectWalletConfig={connectWalletConfig} />}
    </div>
  </div>
);

const MobileMenu: React.FC<MobileMenuProps> = ({ user }) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button variant="ghost">
        <MenuIcon className="h-6 w-6" /> {/* Use MenuIcon as the trigger */}
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent className="flex flex-col justify-between p-0">
      <div className="flex flex-col">
        <DropdownMenuItem>
          <a href="/insights/dashboards">
            <Text variant="button">Insights</Text>
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <a href="/earn/">
            <Text variant="button">Quests</Text>
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <a href="/home/chains">
            <Text variant="button">For Chains</Text>
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <a href="/home/analysts">
            <Text variant="button">For Analysts</Text>
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <a href="/home/explorers">
            <Text variant="button">For Explorers</Text>
          </a>
        </DropdownMenuItem>
        <AboutMenuContent />
      </div>
      {!user && (
        <div className="flex p-4 gap-x-4">
          <>
            <HeaderAuthButton variant="outline" href="/home/login" className="w-full max-w-full">
              Log in
            </HeaderAuthButton>
            <HeaderAuthButton variant="default" href="/home/login?screen_hint=signup" className="w-full max-w-full">
              Sign up
            </HeaderAuthButton>
          </>
        </div>
      )}
    </DropdownMenuContent>
  </DropdownMenu>
);
